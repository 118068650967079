.site-title::before {
    content: url("/assets/img/logo.png");
    display: inline-block;
    position: relative;
    width: 37px;
    top: 5px;
}

img.centered {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

img.circular {
    border-radius: 50%;
}

img.quarter {
    width: 25%;
}

img.half {
    width: 50%;
}
